<template>
	<div class="customer_popup" v-if="success">
		<van-popup v-model="customer_show">
			<div class="customer_box">
				<div class="customer_qrcode">
					<img :src="customer_qrcode" alt="">
				</div>
        <div class="cu_add">添加{{customer_name}}</div>
				<dir class="customer_msg"><span class="f12">长按二维码添加好友即可</span></dir>
			</div>
		</van-popup>
	</div>
</template>

<script>
import Vue from 'vue'
import { getCustomerInfo } from '@/api/customer'
import { Popup } from 'vant'
import ls from '@/libs/localstore'

Vue.use(Popup);

export default {
  components: {},
  data () {
    return {
      success: false,
      customer_name: '',
      customer_account: '',
      customer_qrcode: '',
      customer_show: false
    }
  },
  computed: {},
  created () {},
  mounted () {
    this._loadData()
  },
  methods: {
    show () {
      this.customer_show = true
    },
    _loadData () {
      getCustomerInfo().then((res) => {
        if (res.errCode == 0) {
          this.success = true
          this.customer_name = res.data.customer_name
          this.customer_account = res.data.customer_account
          this.customer_qrcode = res.data.customer_qrcode
          ls.setCustomer(res.data)
        }
      })
    }
  }
}
</script>

<style scoped>
.cu_add{
  font-size: 18px;
  margin-top: 10px;
}
.customer_popup .van-popup{
	border-radius: 10px;
	overflow: hidden;
}

.customer_box {
	padding: 12px 10px 20px 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.customer_qrcode {
	width: 220px;
	height: 220px;
}

.customer_qrcode img {
	width: 100%;
}
</style>
